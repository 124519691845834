<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import documentManagerGEN from "@/components/widgets/documentManagerGEN";
import calendarWidget from "@/components/widgets/calendarWidget";
import customerDataTableSupport from "@/components/widgets/worksites/customerDataTableSupport";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';
import {
    required,
    email,
    helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  page: {
    title: "Dettaglio cliente",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let files = ref([]);
    moment.locale('it')
    let tk = 'Bearer '+localStorage.getItem('tk')

    return { files, tk, v$: useVuelidate() };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        showData:false,
        title: "Dettaglio cliente",
        data:{
          status: "init",
          CodCliFor: 0,
          code:  0,
          TipoSoggetto: 1,
          TipoSoggettoDesc:  "",
          ragSoc:  "",
          ragSoc2: "",
          nome: "",
          cognome: "",
          dataDiNascita:  "",
          luogoDiNascita : "",
          sigla: "",
          codiceFiscale: "",
          codeDest: "",
          email: "",
          pec: "",
          partitaIva: "",
          codiceAteco: "",
          nRea:"",
          artigiano:  "",
          telefono:  "",
          telefono_2:  "",
          indirizzo: "",
          citta: "",
          cap: "",
          provincia: "",
          CodStato: "IT",
          regimeFiscale:  "",
          regimeContabile:  "",
          nomeFascicolo:  "",
          iban: "",
          CodCondizPagamento:  "",
          DesCondizPagamento: "",
          is_staff: false,
          is_active:  false,
        },
        items: [
          {
            text: "Gestione clienti",
            href: "/customers/list",
          },
          {
            text: "Cliente",
            active: true,
          },
        ],
        showMainDocuments:false,
        showMainCalendar:false,
        showMainProjectList:false,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        test:{},
        checkUserdataInEs:false,
        retrCode:0,
        codiceConfermato:0,
        optionsModPayConfigList:[],
    };
  },
  validations: {
    data: {
        TipoSoggetto:{
          required: helpers.withMessage("Tipo soggetto obbligatorio", required),
        },
        ragSoc:{
          required: helpers.withMessage("Ragione sociale obbligatoria", required),
        },
        nome: {
          required: helpers.withMessage("Nome obbligatorio", required),
        },
        cognome: {
          required: helpers.withMessage("Campo Cognome obbligatorio", required),
        },
        email: {
          required: helpers.withMessage("Campo Email obbligatorio", required),
          email: helpers.withMessage("Per favore, inserisci una email valida", email),
        },
        codiceFiscale: {
          required: helpers.withMessage("Campo Codice Fiscale obbligatorio", required),
        },
        partitaIva: {
          required: helpers.withMessage("Campo Partita Iva obbligatorio", required),
        },
        indirizzo: {
          required: helpers.withMessage("Campo Indirizzo obbligatorio", required),
        },
        citta: {
          required: helpers.withMessage("Campo Città obbligatorio", required),
        },
        cap: {
          required: helpers.withMessage("Campo CAP obbligatorio", required),
        },
        provincia: {
          required: helpers.withMessage("Campo Provincia obbligatorio", required),
        },
        telefono: {
          required: helpers.withMessage("Campo Telefono obbligatorio", required),
        },
        CodCondizPagamento: {
          required: helpers.withMessage("Campo Modalità di pagamento obbligatorio", required),
        },
      },

    },
  mounted() {
    this.getItem()
  },

  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    submitForm(){
     
      document.getElementById("genDataForm").submit();
    },
    getParent(){
      this.getItem()
    },
    getItem() {
        this.data = {}
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}customers/${this.$route.params.id}`, 
        {headers: { authorization:this.tk}} )
        .then((response) => {
            this.data = response.data.customerData
            this.getModPayConfigList()
        })
    },
    getModPayConfigList() {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}esync/get-mod-pay-config`, 
        {headers: { authorization:this.tk}} )
        .then((response) => {
            if (response) {
              if (response.data.status=='data') {
                this.optionsModPayConfigList = response.data.results
                this.optionsModPayConfigList.sort(function (a, b) {
                  if (a.Des < b.Des) {
                    return -1;
                  }
                  if (a.Des > b.Des) {
                    return 1;
                  }
                  return 0;
                });

              } else {
                Swal.fire({
                    title: "Errore di configurazione",
                    text: "Non è possibile ottenere i dati di configurazione delle modalità di pagamento provenienti dal gestionale.",
                    icon: 'error',
                    timer:2000,
                  })
              }
            }
            
        })
    },
    modPayFilter() {
        if ( this.optionsModPayConfigList.length>0 ) {
          let self = this
          let payList = this.optionsModPayConfigList.filter(function(el){
            return el.CodCondizPagamento == self.data.CodCondizPagamento
          })
          if (payList) {
            this.data.DesCondizPagamento = payList[0].Des
          }
        }
    },
    updatedata() {
        this.submitted = true;
        this.v$.$touch()
        this.modPayFilter()
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}customers/register-in-es`, this.data, 
         {headers: { authorization:this.tk}} )
          .then((response) => {
            if(response.data){
              if(response.data.result){
                if (response.data.result.status == 'data-complete') {      
                  Swal.fire({
                    title:"Anagrafica già presente",
                    text:"Dal codice fiscale o dalla partita iva da te inserita, sembra che il cliente abbia già un'anagrafica nel nostro sistema. Utilizza il modulo 'Seleziona cliente' per assegnare l'anagrafica a questo progetto.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:5000,
                  })
                } 
                if  (response.data.result.status == 'OK') { 
                  if (response.data.result.response.customerUpdate.modifiedCount == 1 && response.data.result.response.resultES==200) {
                  Swal.fire(  this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
                  }
                  if (response.data.result.response.customerUpdate.modifiedCount == 0 && response.data.result.response.resultES==200) {
                    Swal.fire(  this.$t("t-msg-partially-updated-es") , this.$t("t-msg-partially-updated-content-es"), "warning");
                  }
                  if (response.data.result.response.customerUpdate.modifiedCount == 1 && response.data.result.response.resultES!=200) {
                    Swal.fire(  this.$t("t-msg-partially-updated-app") , this.$t("t-msg-partially-updated-content-app"), "warning");
                  }
                

                }
              }
            }

          })
    },
    validaCodiceFiscale(cf)
        {
          var validi, i, s, set1, set2, setpari, setdisp;
          if( cf == '' )  return '';
          cf = cf.toUpperCase();
          if( cf.length != 16 )
            return false;
          validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
          for( i = 0; i < 16; i++ ){
            if( validi.indexOf( cf.charAt(i) ) == -1 )
              return false;
          }
          set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
          setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
          s = 0;
          for( i = 1; i <= 13; i += 2 )
            s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
          for( i = 0; i <= 14; i += 2 )
            s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
          if( s%26 != cf.charCodeAt(15)-'A'.charCodeAt(0) )
            return false;
          return true;
    },
    controllaCF(codfis){
            if (codfis.length < 11)
              {
                if (this.validaCodiceFiscale(codfis) == false) {
                  document.getElementById('codiceFiscale').setAttribute('style','border:1px solid red;border-radius:4px')
                  document.getElementById('errCodFisc').innerHTML = "<label style='color:red'>Codice fiscale non valido</label>";
                  Swal.fire({
                    title: "Campo non valido",
                    text: "Il codice fiscale inserito non risulta corretto",
                    icon: 'error',
                    timer:2000,
                  })

              } else {
                document.getElementById('errCodFisc').innerHTML = ""
                document.getElementById('codiceFiscale').setAttribute('style','border:2px solid #eee;')
              
                this.checkUserdataByCode(codfis)

              }
            } else {
              document.getElementById('errCodFisc').innerHTML = ""
              document.getElementById('codiceFiscale').setAttribute('style','border:2px solid #eee;')
            }
    },
    controllaPartitaIVA(pi) {

          if (pi.length < 11 ) {
            document.getElementById('partitaIva').setAttribute('style','border:1px solid red;border-radius:4px')
            document.getElementById('errPIva').innerHTML = "<label style='color:red'>Inserisci Partita Iva corretta</label>";
          Swal.fire({
            title: "Campo non valido",
            text: "La Partita Iva inserita non risulta corretta",
            icon: 'error',
            timer:2000,
          })
          } 
          else if (!/^[0-9]{11}$/.test(pi))
          {
            document.getElementById('partitaIva').setAttribute('style','border:1px solid red;border-radius:4px')
            document.getElementById('errPIva').innerHTML = "<label style='color:red'>Inserisci Partita Iva corretta</label>";
            Swal.fire({
                title: "Campo non valido",
                text: "La Partita Iva inserita non risulta corretta",
                icon: 'error',
                timer:2000,
            })
            } else {
            var s = 0;
            for (let i=0; i<=9; i+=2) {
                s += pi.charCodeAt(i) - '0'.charCodeAt(0);
            }
            for (let i=1; i<=9; i+=2 ){
                var c = 2*(pi.charCodeAt(i) - '0'.charCodeAt(0));
                if (c > 9) c = c - 9;
                s += c;
            }
            var controllo = (10 - s%10)%10;
            if (controllo != pi.charCodeAt(10) - '0'.charCodeAt(0)){
                document.getElementById('errPIva').innerHTML = "";
                document.getElementById('partitaIva').setAttribute('style','border:2px solid #eee;')
            }
            this.checkUserdataByCode(pi)
          
          }
    },
    checkUserdataByCode(code){           
          if (code.length >= 11)
          {
          
            if (this.tk) {
              let q = code.trim()
              this.check_code = q
              try {
                let url = `${serverConfig.EP}/customers/get-userdata-from-es`;
                axios.defaults.headers.common['authorization'] = this.tk
                let data = {
                  id:this.data._id,
                  code: this.check_code,
                }
                axios.post(url,data).then((response) => {
                    this.checkUserdataList = response.data
                    if (response.data.status == 'data' || response.data.status == 'data-in-es' ) {
                      this.checkUserdataInEs = true
                      if (response.data.results.length>0){
                        this.retrCode = response.data.results[0].CodCliFor
                        Swal.fire({
                          title:"Anagrafica presente nel gestionale",
                          text:"Dal codice fiscale o dalla partita iva da te inserita, sembra che il cliente abbia già un'anagrafica nel gestionale in uso.",
                          icon:"info",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:4000,
                        })
                      }
                    } else if (response.data.status == 'data-complete') {
                      this.checkUserdataInEs = false
                      Swal.fire({
                          title:"Anagrafica già presente",
                          text:"Dal codice fiscale o dalla partita iva da te inserita, sembra che il cliente abbia già un'anagrafica nel nostro sistema. Utilizza il modulo 'Seleziona cliente' per assegnare l'anagrafica a questo progetto.",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:5000,
                        })
                    }
                    else if (response.data.status == 'data-db-app') {
                      this.checkUserdataInEs = false
                      Swal.fire({
                          title:"Anagrafica da registrare nel gestionale",
                          text:"Dal codice fiscale o dalla partita iva da te inserita, sembra che il cliente abbia già un'anagrafica nell'applicazione, ma non ancora inviata al gestionale. E' necessario procedere con la registrazione completa al sistema",
                          icon:"warning",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:4000,
                        })
                    }
                     else {
                      this.checkUserdataInEs = false
                    }
                })
              } catch {
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
              }

            }
          }

    },
    swlDataImport() {
      if( parseInt(this.codiceConfermato)>0 ){
            if (parseInt(this.codiceConfermato)==parseInt(this.retrCode))
            {
              let url = `${serverConfig.EP}/customers/check-and-save-userdata-from-es`;
              axios.defaults.headers.common['authorization'] = this.tk
              let data = {
                id:this.data._id,
                code:this.retrCode,
                piva:this.check_code,
              }
              
              axios.post(url,data,
                {
                  onUploadProgress: (event) =>
                  {
                    const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                    let timerInterval;
                    Swal.fire({
                      title: "Caricamento...",
                      timer: percentUploaded,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading();
                       // const timer = Swal.getPopup().querySelector("b");
                        /**
                          timerInterval = setInterval(() => {
                            timer.textContent = `${Swal.getTimerLeft()}`;
                          }, 100);
                        */
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    }).then((result) => {
                      /* Read more about handling dismissals below */
                      if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('Caricamento...')
                      }
                    });

                  },
                  onDownloadProgress: (event) =>
                  {
                    const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                    let timerInterval;
                    Swal.fire({
                      title: "Download data..",
                      timer: percentDownloaded,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading();
                        //const timer = Swal.getPopup().querySelector("b");
                        /**
                         *timerInterval = setInterval(() => {
                            timer.textContent = `${Swal.getTimerLeft()}`;
                            }, 100);
                         */

                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    }).then(() => {
                      
                    });

                  }
              }
              ).then((response) => {
                if (response.data) {
                  Swal.fire({
                    title: this.$t("t_success_import_data_title"),
                    icon: 'success',
                    timer:2000,
                  })
                  this.checkUserdataInEs=false
                  this.getItem()
                } 
                
              })
            }
            

            
          }

    },
    validateEmail(email) {
          if(email){
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {  // eslint-disable-line
              return true
            } else {
              return false
            }
          } else {
            return false
          }

    },
    checkPartitaIVA(pi) {
          if (pi == '') {
          return false
          } 
          else if (!/^[0-9]{11}$/.test(pi))
          {
          return false
          } else {
          return true
          }
    },
    cancelImportData(){
          this.checkUserdataInEs = false
        },
      Capitalize(value){
          if(value) {
            return value.toUpperCase()
          }
        },
    getFolder(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}vehicles/folder/update/by-id/${this.$route.params.id}`, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          if (response.data) {
            alert(JSON.stringify(response.data))   
            this.getItem()
          }
        })
    },
    mapObj(data,key){
      if (data) {
        let obj =  {
          status: data.status ? data.status : "init",
          CodCliFor: data.CodCliFor ? data.CodCliFor : 0,
          code: data.code ? data.code : 0,
          TipoSoggetto: data.TipoSoggetto ? data.TipoSoggetto : 1,
          TipoSoggettoDesc: data.TipoSoggettoDesc ? data.TipoSoggettoDesc : "",
          ragSoc: data.ragSoc ? data.ragSoc : "",
          ragSoc2: data.ragSoc2 ? data.ragSoc2 : "",
          nome: data.nome ? data.nome : "",
          cognome: data.cognome ? data.cognome : "",
          dataDiNascita: data.dataDiNascita ? data.dataDiNascita : "",
          luogoDiNascita : data.luogoDiNascita ? data.luogoDiNascita : "",
          sigla: data.sigla ? data.sigla : "",
          codiceFiscale: data.codiceFiscale ? data.codiceFiscale : "",
          codeDest: data.codeDest ? data.codeDest : "",
          email: data.email ? data.email : "",
          pec: data.pec ? data.pec : "",
          partitaIva: data.partitaIva ? data.partitaIva : "",
          codiceAteco: data.codiceAteco ? data.codiceAteco : "",
          nRea: data.nRea ? data.nRea : "",
          artigiano: data.artigiano ? data.artigiano : "",
          telefono: data.telefono ? data.telefono : "",
          telefono_2: data.telefono_2 ? data.telefono_2 : "",
          indirizzo: data.indirizzo ? data.indirizzo : "",
          citta: data.citta ? data.citta : "",
          cap: data.cap ? data.cap : "",
          provincia: data.provincia ? data.provincia : "",
          CodStato: data.CodStato ? data.CodStato : "IT",
          regimeFiscale: data.regimeFiscale ? data.regimeFiscale : "",
          regimeContabile: data.regimeContabile ? data.regimeContabile : "",
          nomeFascicolo: data.nomeFascicolo ? data.nomeFascicolo : "",
          iban: data.iban ? data.iban : "",
          CodCondizPagamento: data.CodCondizPagamento ? data.CodCondizPagamento : "",
          DesCondizPagamento: data.DesCondizPagamento ? data.DesCondizPagamento : "",
          is_staff: data.is_staff ? data.is_staff : false,
          is_active: data.is_active ? data.is_active : false,
        }

        return obj[key]

      }

    },
    syncroApp() {
        Swal.fire(
          {
            title: this.$t("t-wa-sync-confirm-setting-title"),
            text: this.$t("t-wa-sync-confirm-setting-text"),
            icon: this.$t("t-wa-sync-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-wa-sync-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-wa-sync-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-wa-sync-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-wa-sync-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
         
          if (result.isConfirmed) {
              this.axiosInterceptor()
              let url = `${serverConfig.EP}/customers/check-and-save-userdata-from-es`;
              axios.defaults.headers.common['authorization'] = this.tk
              if (parseInt(this.data.TipoSoggetto) == 3 || parseInt(this.data.TipoSoggetto) == 2  )
              {
                this.check_c = this.data.codiceFiscale
              } else {
                this.check_c = this.data.partitaIva
              }
              let data = {
                id:this.data._id,
                code:this.data.CodCliFor,
                piva:this.check_c,
              }

              axios.post(url,data,
                { 
                  onUploadProgress: (event) =>
                  {
                    console.log(event)
                    const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                    
                    console.log(percentUploaded)
                    
                    let timerInterval;
                    Swal.fire({
                      title: "Caricamento...",
                      html: "Progresso operazione <b></b>.",
                      timer: percentUploaded,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading();
                        const timer = Swal.getPopup().querySelector("b");
                        console.log("timer")
                        console.log(timer)
                        
                        timerInterval = setInterval(() => {
                          
                          timer.textContent = `${Swal.getTimerLeft()}`;
                        }, 100);
                        
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    }).then((result) => {
                      /* Read more about handling dismissals below */
                      if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('Caricamento...')
                      }
                    });

                  },
                 
                }).then(response=>{
                if (response.data) {
                  Swal.fire(this.$t("t-msg-import") , this.$t("t-msg-content-import"), "success");
                }
              })
          }
        });
      },

  },
  components: {
    documentManagerGEN,
    calendarWidget,
    customerDataTableSupport,
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="data">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1" >
              
              <template v-if="data">
                  <span class="mdi mdi-account-cash mdi-24px pr-5 "></span>
                  Scheda Cliente: 
                  <span class="bold text-primary">
                    <template v-if="mapObj(data,'TipoSoggetto')==3">
                      {{ mapObj(data,'cognome')}} {{ mapObj(data,'nome')}}
                    </template>
                    <template v-else>
                      {{ mapObj(data,'ragSoc')}}
                    </template>
                  </span>
              </template>
              </h4>

            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <b-button variant="outline-secondary" class="waves-effect waves-light"  title="Sincronizza dati con gestionale" @click="syncroApp">
                  <i class="mdi mdi-swap-horizontal align-bottom me-1"></i>
                </b-button>
                &nbsp;
                <b-button variant="outline-dark" class="waves-effect waves-light"  title="Aggiorna dati" @click="getItem">
                  <i class='ri ri-restart-line'></i>
                </b-button>
                &nbsp;
                <label for="FormSelectSystem" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectSystem"
                  v-model="showData"
                />
              </div>
            </div>
          </div>
         <template v-if="data">
          <div class="card-body" v-if="showData">
            <form class="needs-validation" @submit.prevent="updatedata" id="addform">
                  
                      <div class="row rowSearchFormBGce ">
                        <div class="col-md-12 col-lg-12 mt-25">
                          <template v-if="registerSuccess">
                            <b-alert show variant="secondary" class="alert-borderless " role="alert">
                              Inserisci i <strong> dati angrafici </strong> del <strong>nuovo cliente</strong>, per favore.
                            </b-alert>
                          </template>
                          <template v-if="regError==true">
                            <b-alert show variant="danger" class="alert-borderless " role="alert">
                            <strong>Ops, qualcosa è andato storto! </strong>Prova a chiudere la finestra e ripetere l'operazione, per favore.
                            </b-alert>
                          </template>
                         
                        </div>
                      </div>
                      <div class="row rowSearchFormBGce">
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3" v-if="data">
                           
                            <label for="TipoSoggetto" class="form-label">Tipologia cliente <span class="text-danger">*</span></label>
                            <select class="form-select" required aria-label="Tipologia cliente" v-model="data.TipoSoggetto" :class="{
                            'is-invalid': submitted && v$.data.TipoSoggetto.$error,
                          }">
                              <option value="">Seleziona Tipologia cliente</option>
                              <option value="1">Società di Capitali</option>
                              <option value="2">Società di persone</option>
                              <option value="3">Persona fisica</option>
                              <option value="4">PA</option>
                              <option value="5">Altro</option>
                            </select>
                              <div v-for="(item, index) in v$.data.TipoSoggetto.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-1">
                          <div class="mb-3">
                              <label for="CodCliFor" class="form-label">Codice Cliente</label>
                                <input type="text" class="form-control" v-model="data.CodCliFor" id="CodCliFor" readonly/>
                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                              <label for="DBGruppo" class="form-label">Azienda</label>
                              <select class="form-select" v-model="data.DBGruppo">
                                <option value="nd">Seleziona Azienda</option>
                                <option value="SP">Sunservice</option>
                                <option value="ES">S.Energy Solutions</option>
                              </select>
                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-1">
                          <div class="mb-3">
                              <label for="CodCliFor" class="form-label">Progressivo</label>
                               <div class="form-control"><strong>{{ data.code }}</strong></div>
                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-3" >
                          <div class="mb-3">
                            <label for="nomeFascicolo" class="form-label">Nome Fascicolo (Cartella)</label>
                              <div class="form-control-green1"><strong>{{ data.nomeFascicolo }}</strong></div>
                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-3" >
                          <div class="mb-3">
                            <label for="CodCondizPagamento" class="form-label">Modalità di pagamento</label>
                              <select class="form-select" v-model="data.CodCondizPagamento" :class="{
                                  'is-invalid': submitted && v$.data.CodCondizPagamento.$error,
                                }">
                                <template v-if="optionsModPayConfigList.length>0">
                                  <option v-for="item in optionsModPayConfigList"  :value="item.CodCondizPagamento" :key="item"> {{ item.Des }}</option>
                                </template>
                              </select>
                              <div v-for="(item, index) in v$.data.CodCondizPagamento.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                          </div>  
                        </div>
                      </div>
                      <div class="row rowSearchFormBGce">
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <template v-if="data.TipoSoggetto=='3' || data.TipoSoggetto=='2'">
                              <label for="nome" class="form-label">Nome <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="data.nome" id="nome" :class="{
                                    'is-invalid': submitted && v$.data.nome.$error,
                                  }" placeholder="Inserisci Nome cliente" required />
                                <div v-for="(item, index) in v$.data.nome.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </template>
                            <template v-else>
                              <label for="ragSoc" class="form-label">Ragione sociale <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="data.ragSoc" id="ragSoc" :class="{
                                    'is-invalid': submitted && v$.data.ragSoc.$error,
                                  }" placeholder="Inserisci Ragione Sociale" required />
                                <div v-for="(item, index) in v$.data.ragSoc.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </template>

                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-2" v-if="data.TipoSoggetto=='3' || data.TipoSoggetto=='2'">
                          <div class="mb-3">
                            <label for="cognome" class="form-label">Cognome  <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="data.cognome" id="cognome" :class="{
                                    'is-invalid': submitted && v$.data.cognome.$error,
                                  }" placeholder="Inserisci Cognome" required />
                                <div v-for="(item, index) in v$.data.cognome.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                          </div>  
                        </div>
                        <div class="col-md-12 col-lg-2" >
                          <div class="mb-3">
                            <template v-if="data.TipoSoggetto=='3' || data.TipoSoggetto=='2'">
                              <label for="codiceFiscale" class="form-label">Codice Fiscale  <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="data.codiceFiscale" id="codiceFiscale" :class="{
                                    'is-invalid': submitted && v$.data.codiceFiscale.$error,
                                  }" placeholder="Inserisci Codice Fiscale" @blur="controllaCF(data.codiceFiscale)"  required />
                                <div v-for="(item, index) in v$.data.codiceFiscale.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </template>
                            <template v-else>
                              <label for="codiceFiscale" class="form-label">Codice Fiscale</label>
                              <input type="text" class="form-control" v-model="data.codiceFiscale" id="codiceFiscale" @blur="controllaCF(data.codiceFiscale)" />
                            </template>
                            <div id="errCodFisc" class="text-danger"></div>
                          </div>  
                        </div>
                        <div class="col-md-12 col-lg-2" >
                          <div class="mb-3">
                            <template v-if="data.TipoSoggetto=='3'">
                              <label for="partitaIva" class="form-label">Partita Iva </label>
                              <input type="text" class="form-control" v-model="data.partitaIva" id="partitaIva" @blur="controllaPartitaIVA(data.partitaIva)" />
                            </template>
                            <template v-else>
                              <label for="partitaIva" class="form-label">Partita Iva  <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="data.partitaIva" id="partitaIva" :class="{
                                    'is-invalid': submitted && v$.data.partitaIva.$error,
                                  }" placeholder="Inserisci Partita Iva" required  @blur="controllaPartitaIVA(data.partitaIva)"/>
                                <div v-for="(item, index) in v$.data.partitaIva.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </template>
                            <div id="errPIva"></div>
                          </div>  
                        </div>
                        <div class="col-md-12 col-lg-1" >
                          <div class="mb-3">
                            <label for="codeDest" class="form-label">Codice SDI</label>
                              <input type="text" class="form-control" v-model="data.codeDest" id="codeDest" placeholder="Codice SDI" />
                          </div>  
                        </div>
                        <div class="col-md-12 col-lg-3" >
                          <div class="mb-3">
                            <label for="iban" class="form-label">IBAN</label>
                              <input type="text" class="form-control" v-model="data.iban" id="iban" placeholder="Codice IBAN" />
                          </div>  
                        </div>

                      </div>

                       <div class="row rowSearchFormBGce" v-if="data.TipoSoggetto =='3' || data.TipoSoggetto=='2'">
                          <div class="col-md-12 col-lg-2">
                              <div class="mb-3">
                                  <label for="dataDiNascita-field" class="form-label">Data di Nascita</label>
                                  <input type="date" id="dataDiNascita" v-model="data.dataDiNascita" class="form-control" placeholder="Inserisci Codice destinatario" /> 
                              </div>
                          </div>
                          <div class="col-md-12 col-lg-2">
                              <div class="mb-3">
                                  <label for="luogoDiNascita-field" class="form-label">Luogo di Nascita</label>
                                  <input type="text" id="luogoDiNascita" v-model="data.luogoDiNascita" class="form-control" placeholder="Inserisci Luogo di nascita" />
                              </div>
                          </div>
                       </div>
                       <div class="row rowSearchFormBGce" v-if="checkUserdataInEs==false">
                        <div class="col-md-12 col-lg-3">
                            <div class="mb-3"> 
                              <label for="indirizzo" class="form-label">Indirizzo  <span class="text-danger">*</span></label>
                              <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="data.indirizzo" id="indirizzo" :class="{
                                    'is-invalid': submitted && v$.data.indirizzo.$error,
                                  }" placeholder="Inserisci Indirizzo" required />
                                <div v-for="(item, index) in v$.data.indirizzo.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                                <div class="input-group-append"><span class="input-group-text"><i class="ri-home-gear-line"></i></span></div>
                              </div> 
                            </div>                    
                        </div>
                        <div class="col-md-12 col-lg-2">
                            <div class="mb-3">
                                <label for="citta" class="form-label">Città  <span class="text-danger">*</span></label>
                              <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="data.citta" id="citta" :class="{
                                    'is-invalid': submitted && v$.data.citta.$error,
                                  }" placeholder="Inserisci Città" required />
                                <div v-for="(item, index) in v$.data.citta.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                                <div class="input-group-append"><span class="input-group-text"><i class="ri-community-fill"></i></span></div>
                              </div> 
                            </div>                                              
                        </div>
                        <div class="col-md-12 col-lg-1">
                            <div class="mb-3">
                                <label for="cap" class="form-label">CAP  <span class="text-danger">*</span></label>
                              <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="data.cap" id="cap" :class="{
                                    'is-invalid': submitted && v$.data.cap.$error,
                                  }" placeholder="CAP" required />
                                <div v-for="(item, index) in v$.data.cap.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                                <div class="input-group-append"><span class="input-group-text"><i class="ri-home-6-fill"></i></span></div>
                              </div> 

                            </div>                           
                        </div>
                        <div class="col-md-12 col-lg-1">
                          <div class="mb-3">
                                <label for="cap" class="form-label">Prov. (Sigla) <span class="text-danger">*</span></label>
                              <div class="input-group mb-3">
                                <input type="text" class="form-control w-90" v-model="data.provincia" id="provincia" :class="{
                                    'is-invalid': submitted && v$.data.provincia.$error,
                                  }" placeholder="Provincia" maxlength="2" @blur="Capitalize(data.provincia)"  required />
                                <div v-for="(item, index) in v$.data.provincia.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                                <div class="input-group-append"><span class="input-group-text"><i class="ri-community-fill"></i></span></div>
                              </div> 
                            </div>
                        </div>
                       </div>
                       <div class="row rowSearchFormBGce"  v-if="checkUserdataInEs==false">
                            <div class="col-md-12 col-lg-2">
                                <div class="mb-3">
                                  <label for="email" class="form-label">Email  <span class="text-danger">*</span></label>
                                    <div class="input-group mb-3">
                                      <input type="text" class="form-control" v-model="data.email" id="email" :class="{
                                          'is-invalid': submitted && v$.data.email.$error,
                                        }" placeholder="Email" required />
                                      <div v-for="(item, index) in v$.data.email.$errors" :key="index" class="invalid-feedback">
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                      </div>
                                      <div class="input-group-append"><span class="input-group-text"><i class="ri-mail-unread-line"></i></span></div>
                                    </div> 
                                </div>                  
                            </div>
                            <div class="col-md-12 col-lg-2">
                                <div class="mb-3">
                                    <label for="email-field" class="form-label">PEC</label>
                                    <div class="input-group mb-3">
                                      <input type="email" id="pec" v-model="data.pec"  class="form-control" placeholder="Inserisci PEC" required />
                                      <div class="input-group-append"><span class="input-group-text"><i class="ri-mail-unread-line"></i></span></div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-2">
                                <div class="mb-3">
                                    <label for="telefono" class="form-label">Telefono<span class="text-danger">*</span></label>
                                    <div class="input-group mb-3">
                                      <input type="number" class="form-control" v-model="data.telefono" id="telefono" :class="{
                                          'is-invalid': submitted && v$.data.telefono.$error,
                                        }" placeholder="Inserisci numero tel./cell." required />
                                      <div v-for="(item, index) in v$.data.telefono.$errors" :key="index" class="invalid-feedback">
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                      </div>
                                      <div class="input-group-append"><span class="input-group-text"><i class="ri-phone-line"></i></span></div>
                                    </div> 
                                </div>                            
                            </div>
                        </div>
                        <div class="row rowSearchFormBGce"  v-if="checkUserdataInEs==true">
                            <div class="col-md-12 col-lg-12 text-center rowWhite">
                              <h3>{{ $t("t_check_res_title") }}</h3>
                            </div>
                          </div>
                          <div class="row rowSearchFormBGce" v-if="checkUserdataInEs==true">
                            <div class="col-md-12 col-lg-3 text-center"></div>
                            <div class="col-md-12 col-lg-6 text-center rowWhite">
                              <h4>{{ $t("t_check_res_text") }}</h4>
                              
                              <b-alert show variant="primary">
                                <h4><strong>{{ retrCode }}</strong></h4>
                              </b-alert>
                            </div>
                            <div class="col-md-12 col-lg-3 text-center"></div>
                          </div>
                          <div class="row rowSearchFormBGce" v-if="checkUserdataInEs==true">
                            <div class="col-md-12 col-lg-5 text-center"></div>
                            <div class="col-md-12 col-lg-2 text-center">
                              <input type="number" class="form-control" v-model="codiceConfermato" maxlength="5" />
                            </div>
                            <div class="col-md-12 col-lg-5 text-center"></div>
                          </div>
                          <div class="row rowSearchFormBGce mt-10" v-if="checkUserdataInEs==true">
                            <div class="col-md-12 col-lg-5 text-center"></div>
                              <div class="col-md-12 col-lg-1 text-center">
                                <b-button type="button" variant="primary" @click="swlDataImport">
                                  <i class="ri-rocket-line align-bottom me-1"></i>
                                  Importa dati
                                </b-button>
                              </div>
                              <div class="col-md-12 col-lg-1 text-center">
                                <b-button variant="outline-secondary" class="waves-effect waves-light" @click="cancelImportData">Annulla</b-button>
                              </div>
                              <div class="col-md-12 col-lg-5 text-center"></div>
                          </div>
                        <template v-if="checkUserdataInEs==false">
                          <div class="row mt-50 mb-50">
                            <div class="col-md-12 col-lg-12 text-center">
                              <b-button type="submit" variant="success" size="lg">
                                <i class="ri-save-line align-bottom me-1"></i>
                                Aggiorna anagrafica
                              </b-button>
                            </div>
                          </div>
                        </template>

            </form>
          </div>
         </template>
        </div>
      </div>
    </div>
    <div class="row" v-if="data">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <template v-if="data">
                          <template v-if="data.nomeFascicolo && data.nomeFascicolo!=''">
                            <span class="mdi mdi-24px mdi-file-document pr-5 text-success"></span>
                            <span class="pr-5">Documentazione </span> 
                          </template>
                          <template v-else>
                            <span class="mdi mdi-24px mdi-file-document pr-5 text-danger"></span>
                            <span class="pr-5 text-danger bold">Documentazione assente: nome fascicolo non esistente; completa anagrafica cliente.</span> 
                          </template>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-file-document pr-5 text-danger"></span>
                          <span class="pr-5 text-danger bold">Documentazione assente: nome fascicolo non esistente; completa anagrafica cliente.</span> 
                        </template>
                        
                        
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              &nbsp;
              <template v-if="data">
                <template v-if="data.nomeFascicolo && data.nomeFascicolo!=''">
                  <label for="FormSelectSystem" class="form-label text-muted"
                    >Mostra</label
                  >
                  <input
                    class="form-check-input code-switcher"
                    type="checkbox"
                    id="FormSelectSystem"
                    v-model="showMainDocuments"
                  />                          
                </template>
              </template>
              </div>
            </div>
          </div>
         <template v-if="data">
          <div class="card-body" v-if="showMainDocuments">
            <template v-if="data">
              <documentManagerGEN 
                :url="`${this.UrlServer}storage/by/project`"
                :projectID="data._id" 
                :data="data" 
                :bucket="data.nomeFascicolo" 
                type="directory_principale"
                refDirectory="/"
                fileType="multiple" 
                fileAccept="*" 
                mod="customers"
                title="Documentazione cliente"
                v-on:getParent="getParent()"/>
            </template>                
          </div>
         </template>
        </div>
      </div>
    </div>
    <div class="row" v-if="data">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        
                        <span class="mdi mdi-24px mdi-calendar pr-5 "></span>
                        <span class="pr-5">Calendario e scadenze 

                       
                        </span> 
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              &nbsp;
                <label for="FormSelectSystem" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectSystem"
                  v-model="showMainCalendar"
                />
              </div>
            </div>
          </div>
         <template v-if="data">
         
          <div class="card-body" v-if="showMainCalendar">
            <template v-if="data">
              <!-- PREVEDERE COME PARAMETRO DI calendarWidget "type"=> "user", "area", "vehicle", etc. -->
              <calendarWidget type="customer"  :resourceID="data._id" module="customers" />
            </template>                
          </div>
         </template>
        </div>
      </div>
    </div>
    <div class="row" v-if="data">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="mdi mdi-24px mdi-point-of-sale pr-5 "></span>
                        <span class="pr-5">Impianti acquistati
                        </span> 
                      </div>
                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              &nbsp;
                <label for="projectList" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="projectList"
                  v-model="showMainProjectList"
                />
              </div>
            </div>
          </div>
          <template v-if="data">
            <div class="card-body" v-if="showMainProjectList">
              <template v-if="data">
                <customerDataTableSupport  :customerID="data._id"/>
              </template>                
            </div>
          </template>
        </div>
      </div>
    </div>
  </Layout>
</template>
